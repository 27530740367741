let animateCssClassName = [
	{
		label: 'enter',
		children: [
			{label: 'Gradually appear', value: 'fadeIn'},
			{label: 'Enter left', value: 'fadeInLeft'},
			{label: 'Enter right', value: 'fadeInRight'},
			{label: 'Go up', value: 'fadeInUp'},
			{label: 'Go down', value: 'fadeInDown'},
			{label: 'Enter long left', value: 'fadeInLeftBig'},
			{label: 'Enter long right', value: 'fadeInRightBig'},
			{label: 'Long distance up', value: 'fadeInUpBig'},
			{label: 'Go down long distance', value: 'fadeInDownBig'},
			{label: 'Spin into', value: 'rotateIn'},
			{label: 'Rotate clockwise left', value: 'rotateInDownLeft'},
			{label: 'Rotate right counterclockwise', value: 'rotateInDownRight'},
			{label: 'Rotate left counterclockwise', value: 'rotateInUpLeft'},
			{label: 'Rotate right counterclockwise', value: 'rotateInUpRight'},
			{label: 'Pop in', value: 'bounceIn'},
			{label: 'Pop left', value: 'bounceInLeft'},
			{label: 'Pop right', value: 'bounceInRight'},
			{label: 'Improvement', value: 'bounceInUp'},
			{label: 'Pop down', value: 'bounceInDown'},
			{label: 'Speed of light enters', value: 'lightSpeedIn'},
			{label: 'Y axis rotation', value: 'flip'},
			{label: 'Center X axis rotation', value: 'flipInX'},
			{label: 'Center Y axis rotation', value: 'flipInY'},
			{label: 'Left long radius rotation', value: 'rollIn'},
			{label: 'Enter from small to large', value: 'zoomIn'},
			{label: 'Left becomes bigger to enter', value: 'zoomInLeft'},
			{label: 'Right becomes bigger and enters', value: 'zoomInRight'},
			{label: 'Go up and enter', value: 'zoomInUp'},
			{label: 'Go down and enter', value: 'zoomInDown'},
			{label: 'Swipe left to expand', value: 'slideInLeft'},
			{label: 'Swipe right to expand', value: 'slideInRight'},
			{label: 'Swipe up to expand', value: 'slideInUp'},
			{label: 'Swipe down to expand', value: 'slideInDown'},
		]
	},
	{
		label: 'Emphasize',
		children: [
			{label: 'bounce', value: 'bounce'},
			{label: 'Flashing', value: 'flash'},
			{label: 'Zoom in', value: 'pulse'},
			{label: 'Enlarge the spring', value: 'rubberBand'},
			{label: 'Shake left and right', value: 'shake'},
			{label: 'Slightly shake left and right', value: 'headShake'},
			{label: 'Swing left and right', value: 'swing'},
			{label: 'Zoom in, shake, zoom out', value: 'tada'},
			{label: 'Fan swing', value: 'wobble'},
			{label: 'Shake left and right', value: 'jello'},
			{label: 'Y axis rotation', value: 'flip'},
		]
	},
	{
		label: 'drop out',
		children: [
			{label: 'Fade away', value: 'fadeOut'},
			{label: 'Exit left', value: 'fadeOutLeft'},
			{label: 'Exit right', value: 'fadeOutRight'},
			{label: 'Exit up', value: 'fadeOutUp'},
			{label: 'Exit down', value: 'fadeOutDown'},
			{label: 'Long exit left', value: 'fadeOutLeftBig'},
			{label: 'Long exit to the right', value: 'fadeOutRightBig'},
			{label: 'Long exit upward', value: 'fadeOutUpBig'},
			{label: 'Long exit downward', value: 'fadeOutDownBig'},
			{label: 'Spin out', value: 'rotateOut'},
			{label: 'Rotate clockwise left', value: 'rotateOutDownLeft'},
			{label: 'Rotate right counterclockwise', value: 'rotateOutDownRight'},
			{label: 'Rotate left counterclockwise', value: 'rotateOutUpLeft'},
			{label: 'Rotate right counterclockwise', value: 'rotateOutUpRight'},
			{label: 'pop up', value: 'bounceOut'},
			{label: 'Pop left', value: 'bounceOutLeft'},
			{label: 'Pop right', value: 'bounceOutRight'},
			{label: 'Pop up', value: 'bounceOutUp'},
			{label: 'Pop down', value: 'bounceOutDown'},
			{label: 'Light speed in and out', value: 'lightSpeedOut'},
			{label: 'Center X axis rotation', value: 'flipOutX'},
			{label: 'Center Y axis rotation', value: 'flipOutY'},
			{label: 'Left long radius rotation', value: 'rollOut'},
			{label: 'Exit from small to large', value: 'zoomOut'},
			{label: 'Left becomes bigger to exit', value: 'zoomOutLeft'},
			{label: 'Right becomes bigger and exit', value: 'zoomOutight'},
			{label: 'Go up and exit', value: 'zoomOutUp'},
			{label: 'Go down and exit', value: 'zoomOutDown'},
			{label: 'Swipe left to collapse', value: 'slideOutLeft'},
			{label: 'Swipe right to collapse', value: 'slideOutRight'},
			{label: 'Swipe up to hide', value: 'slideOutUp'},
			{label: 'Swipe down to collapse', value: 'slideOutDown'},
		]
	}
]

export default animateCssClassName
