
const configDict = {
	development: {
		baseURL		: 'https://api.senderlead.com/v1',
		PreviewURL	: 'https://cdn.senderlead.com'
	},
	production: {
		baseURL		: 'https://api.senderlead.com/v1',
		PreviewURL	: 'https://cdn.senderlead.com'
	}
}

const currentConfigKey = process.env.NODE_ENV
const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.NODE_ENV === 'development'

const configObj = {
	isDevelop: (isDev || isTest),
	...configDict[currentConfigKey],
	canvasH5Width: 375,
	canvasH5Height: 585 //644
}

export default configObj
